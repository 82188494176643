import styled from 'styled-components'
import media from 'styled-media-query'
import { Link } from 'gatsby'
import { ContainerDasa } from '../shared/container'
import { alvTokens } from '@dasa-health/alma-tokens'

export const ContactWrapper = styled(ContainerDasa)`
  display: flex;
  flex-direction: column;
  position: relative;
`

export const Header = styled.h1`
  color: ${props => props.theme.colors.brand.primary[10]};
  font-size: 40px;
  line-height: 120%;
  letter-spacing: -1px;
  margin: 0 0 2.4rem;

  ${media.lessThan('medium')`
    font-size: 20px;
    margin: 0 0 2.4rem;
  `}
`

export const ContactContent = styled.div``

export const TextContact = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${alvTokens.ColorNeutralInverse20};
  line-height: 170%;
  margin: 0 0 4rem;
  
  ${media.lessThan('medium')`
    color: ${alvTokens.ColorPaletteGray30};
    margin: 0 0 3.2rem;
  `}
`

export const ContactCardsDiv = styled.div`
  display: flex;
  gap: 3.2rem;

  ${media.lessThan('medium')`
    flex-direction: column;
    gap: 2.4rem;
  `}
`

export const ContactCardsTitle = styled.p`
  color: ${alvTokens.ColorPaletteGray20};
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
`

export const ContactCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 41.5rem;

  ${media.lessThan('medium')`
    margin-bottom: 3.2rem;
  `}
`

export const ContactCards = styled.div`
  display: flex;
  gap: 1.6rem;
  flex-direction: column;
`

export const ContactCardBlue = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.brand.primary[10]};
  border-radius: 8px;
  padding: 2.4rem;
  width: 100%;

  ${media.lessThan('medium')`
    width: auto;
    min-height: 10.8rem;
  `}

  svg {
    color: var(--white);
    font-size: 2.4rem;
    margin-bottom: 1.2rem;
  }
`
export const ContactCardPink = styled(ContactCardBlue)`
  background-color: var(--pink);
`

export const ContactCardLink = styled(Link)`
  font-weight: 700;
  font-size: 16px;
  color: var(--white);
  text-decoration: none;
  `
export const ContactCardText = styled.p`
  font-size: 16px;
  color: var(--white);
  margin: 1.2rem 0;

  &:last-child {
    margin: 0;
  }
`

export const UserImgContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: -11.07rem;
  width: 30.88rem;
  height: 35rem;

  svg {
    max-width: 100%;
    min-height: 35rem;
  }

  ${media.lessThan('medium')`
    display: none;
  `}
`

export const ContentCardTitle = styled(Link)`
  display: flex;
  justify-content: start;
  gap: 8px;
  align-items: start;
  cursor: pointer;
  text-decoration: none;

  svg{
    padding-bottom: 12px;
  }
`

export const CardTitleContact = styled.h3`
  color: #fff;
  font-size: 18px;
  padding-top: 2px;

  @media screen and (max-width:900px){
    font-size: 16px;
  }

`
