import React from 'react'

const UserImg = () => {
    return (
        <svg 
            viewBox="0 0 309 350"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M153.67 168.211C64.4737 168.211 0 209.416 0 314.61V349.998H34.903V313.64C34.903 240.926 70.7756 202.144 153.67 202.144C237.535 202.144 273.892 241.41 273.892 314.125V349.998H308.795V315.095C308.795 213.294 243.352 168.211 153.67 168.211Z" fill="white"/>
            <path d="M153.67 158.518C200.692 158.518 236.08 127.493 236.08 79.0166C236.08 31.0249 200.692 0 153.67 0C106.648 0 71.7451 31.0249 71.7451 79.0166C71.7451 127.493 106.648 158.518 153.67 158.518ZM153.67 33.4487C180.817 33.4487 201.177 51.385 201.177 79.0166C201.177 107.133 180.817 125.069 153.67 125.069C126.523 125.069 106.648 107.133 106.648 79.0166C106.648 51.385 126.523 33.4487 153.67 33.4487Z" fill="white"/>
            <path d="M153.67 125.069C126.523 125.069 106.648 107.133 106.648 79.0166C106.648 51.385 126.523 33.4487 153.67 33.4487C180.817 33.4487 201.177 51.385 201.177 79.0166C201.177 107.133 180.817 125.069 153.67 125.069ZM153.67 158.518C200.692 158.518 236.08 127.493 236.08 79.0166C236.08 31.0249 200.692 0 153.67 0C106.648 0 71.7451 31.0249 71.7451 79.0166C71.7451 127.493 106.648 158.518 153.67 158.518Z" fill="#D22673"/>
            <path d="M0 349.998H34.903V313.64C34.903 240.926 70.7756 202.144 153.67 202.144C237.535 202.144 273.892 241.41 273.892 314.125V349.998H308.795V315.095C308.795 213.294 243.352 168.211 153.67 168.211C64.4737 168.211 0 209.416 0 314.61V349.998Z" fill="#000E40"/>
        </svg>
    )
}

export default UserImg
