import styled from 'styled-components'
import media from 'styled-media-query'

export const PinkLine = styled.div`
  width: 100%;
  height: 8rem;
  background-color: var(--pink);

  ${media.lessThan('medium')`
    height: 3.2rem;
  `}
`
